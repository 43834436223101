import React from 'react';
import { useNavigate } from 'react-router-dom';
import { cerrarSesion } from '../../services/auth';

const NavBar = () => {

    const navigate = useNavigate();

    const handleLogout = () => {
        cerrarSesion();
    }

    const handleAddNew = () => {
        navigate('/blogs')
    }

    const handleDashboard = () => {
        navigate('/dashboard')
    }


    return (
        <div className='navbar-content'>
            <div>
                <img src='/images/xsc-logo.png' onClick={handleDashboard} />
            </div>
            <div className='navbar-buttons'>
                <button onClick={handleAddNew} className="btn-general">Add new</button>
                <button onClick={handleLogout} className="btn-general">Logout</button>
            </div>
        </div>
    )
}

export default NavBar;