import React, { useState } from 'react'
import { login } from '../services/auth'

export const Login = () => {


    const [dataForm, setDataForm] = useState({
        email: "",
        password: ""
    })

    const handleChange = (e) => {
        setDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = await login(dataForm.email, dataForm.password);

    }


    return (
        <div className='login-content'>
            <div>
                <div id='login'>
                    <div>
                        <h1>Login</h1>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Email</label>
                            <input  type="email" 
                                    placeholder='Insert your email...'
                                    name='email'
                                    value={dataForm.email}
                                    onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Password</label>
                            <input  type="password"
                                    placeholder='Insert your password...'
                                    name='password'
                                    value={dataForm.password}
                                    onChange={handleChange}
                            />
                        </div>
                        <div>
                            <button type='submit'>Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
