import React, { useEffect, useState } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import { Blogs } from "../pages/Blogs";
import { BlogShow } from "../pages/BlogShow";
import Dashboard from "../pages/Dashboard";
import { Login } from "../pages/Login";
import { getCurrentAuthUser } from "../services/auth";


export default function AppRouter() {


    const [user, setUser] = useState(null);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        getCurrentAuthUser(setUser, setLoad);
    }, []);
    

    return (
        <>
            {
                !load &&
                <BrowserRouter>
                    <Routes>
                        <Route path='/dashboard' element={user? <Dashboard /> : <Navigate to='/login' />}/>
                        <Route path='/blogs' element={user? <Blogs key={'create'} /> : <Navigate to='/login' />}/>
                        <Route path='/blogs/edit/:id' element={user? <Blogs key={'edit'} /> : <Navigate to='/login' />}/>
                        <Route path='/blogs/show/:id' element={user? <BlogShow /> : <Navigate to='/login' />}/>

                        <Route path='/login' element={!user? <Login /> : <Navigate to='/dashboard'/>}/>

                        <Route path='/*' element={<Navigate to="/login" />}/>
                    </Routes>
                </BrowserRouter>
            }
        </>
    );
}

