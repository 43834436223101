import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";





const firebaseConfig = {
  apiKey: "AIzaSyBt-uUjaXDXbwCOmRGEermZch6qHbbf5VE",
  authDomain: "phoenixblog-1dc42.firebaseapp.com",
  projectId: "phoenixblog-1dc42",
  storageBucket: "phoenixblog-1dc42.appspot.com",
  messagingSenderId: "151741147649",
  appId: "1:151741147649:web:e5c125dfef838c41486e00",
  measurementId: "G-T5EFFB9VLG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);



// Firestore
const db = getFirestore(app);


// Autenticacion
const auth = getAuth(app);

// Storage
const storage = getStorage(app);


export {
    app,
    auth,
    db,
    storage
}


