import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import BlogResume from '../components/BlogResume';
import NavBar from '../components/layout/NavBar';
import { countLen, deleteBlog, getBlogs, getPagination } from '../services/blog';


const Dashboard = () => {

    const limite = 6;

    const navigate = useNavigate();

    const [blogs, setBlogs] = useState(null);
    const [numberPages, setNumberPages] = useState(1);
    const [actPage, setActPage] = useState(1);
    const [loading, setLoading] = useState(false);

    
    const handleEdit = (doc) => {
        navigate(`/blogs/edit/${doc.id}`)
    }
    
    const handleShow = (doc) => {
        navigate(`/blogs/show/${doc.id}`)
    }
    
    const handleDelete = (id) => {
        deleteBlog(id, setBlogs);
    }

    const handlePageClick = (e) => {
        const page = e.selected + 1;
        setActPage(page);
        //console.log('pagina cambio', page)
    }
    
    useEffect(() => {
        countLen(limite, setNumberPages);        
        //getBlogs(setBlogs);
        //getPagination(actPage, limite, setBlogs);
    }, []);

    useEffect(() => {
        setLoading(true);
        getPagination(actPage, limite, setBlogs, setLoading);
    }, [actPage]);
    
    

    return (
        <>
            <NavBar />
            <div className='dashboard-content'>
                {
                    loading &&
                    <p className='blogs-loading'>Loading...</p>
                }
                {
                    blogs &&
                    blogs.map((doc) => (
                        <BlogResume key={doc.id} doc={doc} handleDelete={handleDelete} handleEdit={handleEdit} handleShow={handleShow} />
                    ))
                }
            </div>

            <div id="react-pagination">
                <ReactPaginate
                    breakLabel="..."
                    //nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={numberPages}
                    //previousLabel="< previous"
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    )
}

export default Dashboard;