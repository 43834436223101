import React, { useState } from 'react';
import { changeStatusActiveOrInactive } from '../services/blog';


const BlogResume = ({doc, handleShow, handleEdit, handleDelete}) => {

    const [isCheck, setIsCheck] = useState(doc.active);


    const handleActiveInactive = (id) => {
        //console.log('valor', !isCheck)
        changeStatusActiveOrInactive(id, !isCheck);
        setIsCheck(!isCheck);
    }

    return (
        <div key={doc.id} className="blog-small-container">
            <div style={{marginBottom: '20px'}}>
                <h2 className='blog-title'>{doc.title}</h2>
                <p className="max-lines-5" dangerouslySetInnerHTML={{__html: doc.blog}}></p>
            </div>
            <div>
                <button onClick={() => handleShow(doc)} className="btn-general">Preview</button>
                <button onClick={() => handleEdit(doc)} className="btn-general">Edit</button>
                <button onClick={() => handleDelete(doc.id)} className="btn-general">Delete</button>
                <label>
                    <input type="checkbox" id={doc.id} checked={isCheck} onChange={() => handleActiveInactive(doc.id)} /> 
                    Active
                </label>
            </div>
        </div>
    );
}

export default BlogResume;