import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import Blog from '../components/Blog';
import NavBar from '../components/layout/NavBar';
import { categories } from '../constants/categories';
import { addImage, createBlog, getBlog, getImage } from '../services/blog';


export const Blogs = () => {

    let params = useParams();

    const [data, setData] = useState(null);
    const [value, setValue] = useState('');
    const [title, setTitle] = useState('');
    const [selected, setSelected] = useState(categories[0]);
    const [tags, setTags] = useState('');

    const [myImage, setMyImage] = useState('');
    const [myImage02, setMyImage02] = useState('');
    const [myImage03, setMyImage03] = useState('');

    const [myImageURL01, setMyImageURL01] = useState('');
    const [myImageURL02, setMyImageURL02] = useState('');
    const [myImageURL03, setMyImageURL03] = useState('');

    const [loadSave, setLoadSave] = useState(false);


    useEffect(() => {
        if(params.id) {
            getBlog(params.id, setTitle, setValue, setData);
            getImage(`/images/${params.id}-01`, setMyImageURL01)
            getImage(`/images/${params.id}-02`, setMyImageURL02)
            getImage(`/images/${params.id}-03`, setMyImageURL03)
        }
    }, [])


    useEffect(() => {
      if(data) {
        if(data.tags) {
            setTags(data.tags.join(', '))
        }
        if(data.selected) {
            //console.log('sele', data.selected)
            setSelected(data.selected);
        }
      }
    }, [data]);
    
    



    const handleImageChange_570x360 = (ev) => {

        if( !ev.target.files[0] ) {
            return;
        }

        const reader = new FileReader();
        //console.log('e', ev.target.files[0])

        reader.readAsDataURL(ev.target.files[0]);
        reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = () => {
              const {
                height,
                width
              } = image;

              if (height === 360 && width === 570) {
                setMyImage(ev.target.files[0]);
                return false;
              }
              Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Something went wrong!',
              })
              ev.target.value = ''
              return true;
            };
        };

    };

    const handleImageChange_840x380 = (ev) => {

        if( !ev.target.files[0] ) {
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(ev.target.files[0]);
        reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = () => {
              const {
                height,
                width
              } = image;

              if (height === 380 && width === 840) {
                setMyImage02(ev.target.files[0]);
                return false;
              }
              Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Something went wrong!',
              })
              ev.target.value = ''
              return true;
            };
        };

    };

    const handleImageChange_70x65 = (ev) => {

        if( !ev.target.files[0] ) {
            return;
        }

        const reader = new FileReader();

        //console.log('e', ev.target.files[0])

        reader.readAsDataURL(ev.target.files[0]);
        reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = () => {
              const {
                height,
                width
              } = image;

              if (height === 65 && width === 70) {
                setMyImage03(ev.target.files[0]);
                return false;
              }
              Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Something went wrong!',
              })
              ev.target.value = ''
              return true;
            };
        };

    };


    const handleTitle = (e) => {
        setTitle(e.target.value)
    }


    const handleSelect = (e) => {
        setSelected(e.target.value)
    }

    const handleTags = (e) => {
        setTags(e.target.value);
    }


    const handleSave = async () => {

        setLoadSave(true);

        let arrTags = tags.split(',');
        arrTags = arrTags.map(tag => tag.trim());
        const arrSet = new Set(arrTags);
        const arrayTags = [...arrSet];

        if(params.id) { // EDIT
            const isSave = await createBlog(params.id, title.trim(), value, selected, arrayTags, true);

            if( myImage!=='' ) {
                await addImage(myImage, `${params.id}-01`);
            }
            if( myImage02!=='' ) {
                await addImage(myImage02, `${params.id}-02`);
            }
            if( myImage03!=='' ) {
                await addImage(myImage03, `${params.id}-03`);
            }

        } else {
            if( myImage!=='' && myImage02!=='' && myImage03!=='' ) {
                const id = uuidv4();
                const isSave = await createBlog(id, title.trim(), value, selected, arrayTags, false);
                await addImage(myImage, `${id}-01`);
                await addImage(myImage02, `${id}-02`);
                await addImage(myImage03, `${id}-03`);
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Images are required!',
                })
            }
        }

        setLoadSave(false);
    }


    return (
        <>
            <NavBar />
            <div className='blog-edit-container'>
                <h3>Title</h3>
                <input type='text' name='title' value={title} onChange={handleTitle}/>
                <hr />

                <div>
                    <Blog value={value} setValue={setValue} />
                </div>

                <hr />

                <div className='blog-select-content'>
                    <label htmlFor="categories">Choose a category:</label>
                    <select name="categories" id="categories" onChange={handleSelect}>
                        {
                            categories.map((item, index) => (
                                <option selected={selected === item} key={index} value={item}>{item}</option>
                            ))
                        }
                    </select>
                </div>

                <div className='blog-tags-content'>
                    <p>Tags: (separated by commas)</p>
                    <input 
                        type='text'
                        name='tags'
                        placeholder='Ej: renovation, repairing, leakage, ...'
                        value={tags}
                        onChange={handleTags}
                    />
                </div>

                <div className='blog-image-input-content'>

                    {
                        myImageURL01!=='' && myImage==='' &&
                        <div className='blog-img-show-content'>
                            <img src={myImageURL01}/>
                        </div>
                    }
                    {
                        myImage !== '' &&
                        <div className='blog-img-show-content'>
                            <img src={URL.createObjectURL(myImage)} alt="570x360" />
                        </div>
                    }

                    <label htmlFor="">Select Image (570x360)</label>
                    <input
                        type="file"
                        name="image"
                        accept="image/*"
                        className="form-control"
                        onChange={(e) => handleImageChange_570x360(e)}
                    />
                </div>

                <div className='blog-image-input-content'>

                    {
                        myImageURL02!=='' && myImage02==='' &&
                        <div className='blog-img-show-content'>
                            <img src={myImageURL02}/>
                        </div>
                    }
                    {
                        myImage02 !== '' &&
                        <div className='blog-img-show-content'>
                            <img src={URL.createObjectURL(myImage02)} alt="570x360" />
                        </div>
                    }

                    <label htmlFor="">Select Image (840x380)</label>
                    <input
                        type="file"
                        name="image02"
                        accept="image/*"
                        className="form-control"
                        onChange={(e) => handleImageChange_840x380(e)}
                    />
                </div>

                <div className='blog-image-input-content'>

                    {
                        myImageURL03!=='' && myImage03==='' &&
                        <div className='blog-img-show-content'>
                            <img src={myImageURL03}/>
                        </div>
                    }
                    {
                        myImage03 !== '' &&
                        <div className='blog-img-show-content'>
                            <img src={URL.createObjectURL(myImage03)} alt="570x360" />
                        </div>
                    }

                    <label htmlFor="">Select Image (70x65)</label>
                    <input
                        type="file"
                        name="image03"
                        accept="image/*"
                        className="form-control"
                        onChange={(e) => handleImageChange_70x65(e)}
                    />
                </div>

                <br/>
                <div>
                    <button onClick={handleSave} disabled={loadSave} className="btn-general">{!loadSave? 'Save' : 'Loading..'}</button>
                </div>
            </div>
        </>
    )
}
