import { onAuthStateChanged, signInWithEmailAndPassword, signOut  } from "firebase/auth";
import { auth } from "../firebase/config";



export const login = async(email, password) => {
    const userAuth = await signInWithEmailAndPassword(auth, email, password);
    return userAuth.user;
}


export const getCurrentAuthUser = (setUser, setLoad) => {
    onAuthStateChanged(auth, (user) => {
        setUser(user);
        setLoad(false);
    });
} 


export const cerrarSesion = async() => {
    await signOut(auth);
}




