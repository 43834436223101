import { setDoc, getDoc, doc, collection, getDocs, deleteDoc, limit, orderBy, query, startAfter, updateDoc  } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Swal from "sweetalert2";
import { db, storage } from "../firebase/config";




export const getBlogs = async (setBlogs) => {
    const arr = [];
    const querySnapshot = await getDocs(collection(db, "blog"));
    querySnapshot.forEach((doc) => {
        arr.push(doc.data())
    });

    setBlogs(arr);
}

export const getPagination = async (pag = 1, limite, setBlogs, setLoading) => {

    let prevId = null;

    for (let index = 1; index <= pag; index++) {

        if(index === 1) {
            const queryHandler = query(
                collection(db, 'blog'),
                orderBy('date', 'desc'),
                limit(limite)
            )
            const result = await getDocs(queryHandler);
            const arr = [];
    
            result.forEach((doc) => {
                if(index===pag) {
                    //console.log(doc.data().title, doc.data().id);
                    arr.push(doc.data());
                }
                prevId = doc.data().id;
            });

            setBlogs(arr);
        } else {
            if(prevId) {
                const docRef = doc(db, "blog", `${prevId}`);
                const docSnap = await getDoc(docRef);

                const queryHandler = query(
                    collection(db, 'blog'),
                    orderBy('date', 'desc'),
                    limit(limite),
                    startAfter(docSnap)
                )
                
                const result = await getDocs(queryHandler);
                const arr = [];
            
                result.forEach((doc) => {
                    prevId = doc.data().id;
                    if(index===pag) {
                        //console.log(doc.data().title, doc.data().id)
                        arr.push(doc.data());
                    }
                });
                setBlogs(arr);
            }
        }

    }

    setLoading(false);
} 


export const countLen = (limite, setNumberPages) => {
    getDocs(collection(db, "blog")).then(resp => {
        setNumberPages(Math.ceil(resp.size/limite));
        //console.log(resp.size)
    });
}


export const getBlog = async (id, setTitle, setValue, setData=null) => {
    const docRef = doc(db, "blog", `${id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const data = docSnap.data();
        if(setData !== null) {
            setData(data);
        } 
        if(setValue !== null) {
            setValue(data.blog);
        }
        if(setTitle !== null) {
            setTitle(data.title);
        }
    }
};

export const changeStatusActiveOrInactive = async (id, active) => {
    try {
        const blogRef = doc(db, "blog", id);
        await updateDoc(blogRef, {
            active: active
        });
    } catch (error) {
        console.log('err', error)
    }
}


export const createBlog = async (id, title, blog, selected, tags, edit=false) => {

    
    try {
        
        if(!edit) {
            const dateAct = new Date();

            await setDoc(doc(db, "blog", `${id}`), {
                id,
                title,
                blog,
                selected,
                tags,
                date: dateAct,
                dateFormat: {
                    dd: dateAct.getDate(),
                    mm: dateAct.getMonth(),
                    yyyy: dateAct.getFullYear()
                },
                active: false
            });
        } else {
            const blogRef = doc(db, "blog", id);
            await updateDoc(blogRef, {
                title,
                blog,
                selected,
                tags
            });
        }


        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Your blog has been saved',
            showConfirmButton: false,
            timer: 1500
        })

        return true;
    } catch (error) {
        return false;
    }
}


export const deleteBlog = (id, setBlogs) => {

    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
        if (result.isConfirmed) {

            await deleteDoc(doc(db, "blog", id));

            setBlogs(ant => {
                const act = ant.filter(blog => id !== blog.id);
                //console.log('act', act)
                return act;
            })

            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
        }
    })

}


export const addImage = async (image, name) => {
    const storeRef = ref(
        storage,
        `/images/${name}`
    );
    await uploadBytesResumable(storeRef, image);
}

export const getImage = (name, setMyImageURL) => {
    const starsRef = ref(storage, `${name}`);

    getDownloadURL(starsRef).then((url) => {
            setMyImageURL(url);
        })
        .catch((error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
            case 'storage/object-not-found':
                // File doesn't exist
                break;
            case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
            case 'storage/canceled':
                // User canceled the upload
                break;

            // ...

            case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;
            }
        });
}



