import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getBlog, getImage } from '../services/blog';
import NavBar from '../components/layout/NavBar';
import { getMonthStringArr } from '../helpers/getMonth';

export const BlogShow = () => {

    const navigate = useNavigate();
    let params = useParams();

    const [data, setData] = useState({});
    const [myImageURL02, setMyImageURL02] = useState('');


    useEffect(() => {
        if(params.id) {
            getBlog(params.id, null, null, setData);
            getImage(`/images/${params.id}-02`, setMyImageURL02);
        } else {
            navigate('/dashboard');
        }
    }, []);
    


    return (
        <>
            <NavBar />
            <div style={{width: '840px', margin: 'auto', marginTop: '50px', marginBottom: '100px'}}>
                <div style={{position: 'relative'}}>
                    <img src={myImageURL02} />
                    <div className='show-fecha'>
                        <span>{data.dateFormat && data.dateFormat.dd} {data.dateFormat && getMonthStringArr[data.dateFormat.mm]}</span>
                    </div>
                </div>

                <div className='by-and-tags'>
                    By: Admin / {data.tags && data.tags.join(', ')}
                </div>

                <h1>{data.title}</h1>
                <p className='blog-body-p' dangerouslySetInnerHTML={{__html: data.blog}}></p>

                <div className='blog-show-category'>
                    <h5>Category:</h5>
                    <span>{data.selected}</span>
                </div>
            </div>
        </>
    )
}
