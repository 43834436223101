import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const Blog = ({value, setValue}) => {

    /*const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            ['clean']
        ],
    }*/

    const modules = {
        toolbar: [
          [{ 'header': [] }, { 'font': [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { 'list': 'ordered' },
            { 'list': 'bullet' },
            { 'indent': '-1' },
            { 'indent': '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
          [{ 'color': [] }, { 'background': [] }],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          // [],
        ],
        
    }
    
    /*const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]*/


    return (
        <ReactQuill id='myBlog'  theme="snow" value={value} onChange={setValue} modules={modules} /*formats={formats}*/ />
    )
}

export default Blog